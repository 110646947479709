import React, { useMemo } from 'react'

import { EmailNotificationLayout } from '../../../components/email-notification'
import SVG from 'react-inlinesvg'

const ITEMS = [
  {
    title: 'Records',
    icon: 'records',
  },
  {
    title: 'Commissions',
    icon: 'commissions',
  },
  {
    title: 'Page views',
    icon: 'page-views',
  },
  {
    title: 'Direct partners',
    icon: 'partners',
  },
]

export default function PlanLimit({ location }) {
  const queryParams = new URLSearchParams(location.search)
  const type = queryParams.get('type')
  const usage_parameter = queryParams.get('parameter')

  const items = useMemo(() => {
    if (type === '1') {
      return ITEMS
    }
    return ITEMS.slice(0, -1)
  }, [type])

  const title = useMemo(() => {
    if (type === '3') {
      return 'The limit for your plan has been exceeded.'
    }
    return 'You are nearing the usage limits of your plan'
  }, [type])

  const description = useMemo(() => {
    if (type === '3') {
      return (
        <p className="text-center text-smaller text-dark p-x-20">
          You've gone over your plan limit, and a&nbsp;
          <span className="text-boldest">{usage_parameter}</span> has been added
          to your account. The corresponding extra charge will be shown on your
          upcoming invoice.
        </p>
      )
    }
    return (
      <p className="text-center text-smaller text-dark p-x-20">
        You're approaching the limits of your&nbsp;
        <span className="text-boldest">{usage_parameter}</span> plan. Going over
        might mean extra charges. Consider upgrading to prevent this.
      </p>
    )
  }, [type])

  return (
    <>
      <EmailNotificationLayout>
        <div className="email-notification-content">
          <h1 className="text-largiestest text-bold text-center text-dark m-t-0">
            {title}
          </h1>
          <div className="email-card email-card-gray">
            {items.map((item, key) => (
              <div key={key} className="column column-center gap-2">
                <SVG
                  src={`/images/email-notifications/play-limits-${item.icon}.svg`}
                  width={40}
                  height={40}
                />
                {item.title}
              </div>
            ))}
          </div>
          {description}
          <button className="btn btn-xl btn-primary text-bold">
            Go to Subscription page
          </button>
          <p className="text-center text-smaller text-primary p-x-20">
            If you often go over your plan's usage limits, it might be a good
            idea to think about upgrading your plan.
          </p>
        </div>
      </EmailNotificationLayout>
    </>
  )
}
